// This file is an aliased import that exposes the jQuery vars as globals
// NOTE --> this file is only imported once from global.js

import $ from 'jquery';
import jQuery from 'jquery';

window.$ = $;
window.jQuery = jQuery;

// patch pre v1.7 jquery events ($.event.handle removed) to resolve errors in jquery.validate
// note ->  jquery-migrate plugin is supposed to be resolved this but does not seem to help so patched instead
// resource - https://stackoverflow.com/questions/16527658/jquery-error-typeerror-event-handle-is-undefined#answer-16527841
$.event.handle = $.event.dispatch;

export default $;
