$.fn.toCustomFields = function ( o ) {
    return this.filter( 'input, select:not(".cs-select")' ).each( this.toCustomField );
}

$.fn.toCustomField = function ( o ) {
    function updatePlaceholder ( $selOption, $sel ) {
        $sel.toggleClass( 'placeholder', !$selOption.val() );
    }

    function handleFocusFor ( $el ) {
        $el.on( 'focusin focusout', function ( e ) {
            $el.toggleClass( 'focus', 'focusin' === e.type );
        } );
    }

    var makeSelect = function () {
        var $sel = $( this ),
            $selOption = $( 'option:selected', this ),
            val = $selOption.text();

        $sel
            .addClass( 'custom' )
            .wrap( `<span class="select ${this.classList}"></span>` )
            .change( function () {
                var $selOption = $( 'option:selected', this ),
                    val = $selOption.text(),
                    target = $( this.parentNode ).find( '.value' );

                updatePlaceholder( $selOption, $sel );
                target && target.text( val );
                this.blur(); // trigger validation
            } );

        $sel = $sel.parents( '.select:first' );
        $sel.$field = $( this );
        updatePlaceholder( $selOption, $sel );
        $( '<span class="value">' + val + '</span>' ).insertBefore( this );

        handleFocusFor( $sel );

        return $sel;
    }

    var makeFileSelect = function () {
        var $field = $( this );

        $field
            .addClass( 'custom' )
            .wrap( '<span class="input-file"></span>' )
            .change( function () {
                var val = parseFilePath.call( this ),
                    target = $( this.parentNode ).find( '.value' );

                target && target.text( val );
                this.blur(); // trigger validation
            } );

        $( '<span class="value">' + parseFilePath.call( this ) + '</span>' ).insertBefore( this );

        function parseFilePath () {
            var val = this.value.split( '\\' );
            return val[val.length - 1];
        };

        handleFocusFor( $field.parents( '.input-file:first' ) );
        return $field;
    }

    var makeCheckOrRadio = function () {
        var $field = $( this ),
            type = this.getAttribute( 'type' ),
            clearRadioList = function () {
                $( ':radio[name="' + this.name + '"]' ).each( function () {
                    toggleField.call( this );
                } );
            },
            toggleField = function () {
                $( this ).siblings( 'span:first' ).toggleClass( 'checked', this.checked );
            };

        $field
            .addClass( 'custom' )
            .wrap( '<span class="wrap-check"></span>' )
            .after( '<span class="' + type + '"></span>' )
            .on( 'click keydown keyup change', function ( e ) {
                if ( 'radio' === this.type ) {
                    clearRadioList.call( this );
                }
                toggleField.call( this );
            } );

        toggleField.call( this );

        return $field;
    }

    var $field = $( this );

    if ( $field.is( 'select' ) ) {
        return makeSelect.call( this );
    }
    else if ( $field.is( ':radio, :checkbox' ) ) {
        return makeCheckOrRadio.call( this );
    }
    else if ( $field.is( ':file' ) ) {
        return makeFileSelect.call( this );
    }

};
