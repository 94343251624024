/**
 * Tooltip
 * Display simple tooltip next to target elements that toggles upon give triggers
 * Note: currently always display 15px to right of target
 */
$.fn.showHint = function () {

    var args = arguments,
        isSmallDevice = Modernizr.mq( 'screen and (max-width: 640px)' ),
        defaults = {
            type: 'tooltip',
            show_triggers: 'mouseenter',
            hide_trigger: 'mouseleave mousedown',
            message: (typeof args[0] == 'string') ? args[0] : null,
            className: '',
            offset_top: null,
            width: 180,
            fx_speed: 200,
            hide_timeout: 10000,
            touch_enabled: false
        },
        opt = (typeof args[0] == 'object') ? args[0] : args[1] || {};

    if ( opt.type === 'error' ) {
        defaults.className = 'error';
        defaults.hide_timeout = 5000;
        // remove default hide triggers
        defaults.hide_trigger = '';
    }

    opt = $.extend( defaults, opt );

    return $( this ).each( function () {
        var $node = $( this ),
            tooltip = $( '<div class="tooltip hint"></div>' ).addClass( opt.className ).addClass( opt.placement ),
            pos = $node.offset(),
            _destroyId;

        // if message not passed to constructor, look at -tooltip attr for message
        // if no message exit fuction
        if ( !opt.message ) {
            opt.message = $node[0].getAttribute( 'data-tooltip' );
            if ( !opt.message ) {
                return;
            }
        }

        tooltip.text( opt.message );

        if ( !isSmallDevice ) {
            tooltip.appendTo( 'body' );

            var tooltipWidth = getWidth( tooltip, opt.width );
            tooltip.css( $.extend( {}, {
                maxWidth: tooltipWidth,
                position: 'absolute',
                zIndex: 1000
            } ) );

            tooltip.css( getHintPosition( tooltip, opt.placement ) );

            tooltip.hide().fadeIn( opt.fx_speed );
        }
        else {
            $node.after( tooltip )
        }

        $node.one( opt.hide_trigger, destroy );

        // destroy after 4 sec
        _destroyId = setTimeout( destroy, opt.hide_timeout );

        $( '#content-pane-scroller' ).on( 'scroll', destroy );

        //** Helpers
        function getWidth ( el, userDefinedWidth ) {
            var offsetWidth = el[0].offsetWidth;
            return (offsetWidth > userDefinedWidth) ? userDefinedWidth : offsetWidth;
        }

        function getHintPosition ( tooltip, placement ) {
            var offsetWidth = tooltip[0].offsetWidth;

            if ( 'bottom' === placement ) {
                return {
                    top: offset_top( 'bottom' ) + 'px',
                    left: offset_left( 'center', offsetWidth ) + 'px'
                }
            }
            else if ( 'left' === placement ) {
                return {
                    top: offset_top( 'top' ) + 'px',
                    left: offset_left( 'left', offsetWidth ) + 'px'
                }
            }
            else {
                return {
                    top: offset_top( 'center' ) + 'px',
                    left: offset_left( 'right', offsetWidth ) + 'px'
                }
            }
        }

        function destroy () {
            if ( _destroyId ) {
                clearTimeout( _destroyId );
                _destroyId = null;
            }

            if ( !isSmallDevice ) {
                tooltip.fadeOut( opt.fx_speed, function () {
                    tooltip.remove();
                } );
            }
            else {
                tooltip.remove();
            }
            // prevent memory leaks: too many event bindings since this method
            // could be bound to key event on form fields
            $node.unbind( opt.hide_trigger, destroy ).trigger( 'tooltip:removed' );
        };

        function offset_top ( offset ) {
            // note -> was causing issues after scrolled
            // const top = pos.top + ($( '#content-pane-scroller' ).scrollTop() >= 48 ? 96 : 0); // 96px header offset
            const top = pos.top;

            switch ( offset ) {
                case 'center':
                    return (top + parseInt( $node[0].offsetHeight / 2 )) -
                        parseInt( tooltip[0].offsetHeight / 2 );
                    break;

                case 'bottom':
                    return (top + $node[0].offsetHeight) + 5;
                    break;

                case 'top':
                default:
                    var marginTop = (isNaN( marginTop = parseInt( $node.css( 'marginTop' ) ) )) ? 0 : marginTop;
                    return top + marginTop;
            }
        }

        function offset_left ( offset, tooltipWidth ) {
            switch ( offset ) {
                case 'left':
                    return pos.left - (tooltipWidth + 10);
                    break;
                case 'center':
                    return pos.left + ($node[0].offsetWidth / 2) - parseInt( tooltipWidth / 2 );
                    break;
                case 'right':
                default:
                    return pos.left + ($node[0].offsetWidth + 10);
                    break;

            }
        }

        function supressAltText () {
            $node.data( 'atlText', $node.attr( 'alt' ) );
            $node.attr( 'alt', '' );
        }

        function restoreAltText () {
            $node.attr( 'alt', $node.data( 'atlText' ) );
            $node.data( 'altText', null );
        }
    } );
};
