// Accommodate running jQuery or Zepto in noConflict() mode by
// using an anonymous function to redefine the $ shorthand name.
// See http://docs.jquery.com/Using_jQuery_with_Other_Libraries
// and http://zeptojs.com/
var libGlobal = null;
if ( typeof jQuery === 'undefined' &&
    typeof Zepto === 'undefined' &&
    typeof $ === 'function' ) {
    libGlobal = $;
}
else if ( typeof jQuery === 'function' ) {
    libGlobal = jQuery;
}
else if ( typeof Zepto === 'function' ) {
    libGlobal = Zepto;
}
else {
    throw new TypeError();
}

module.exports = (function ( $ ) {
    'use strict';

    // polyfills
    if ( !Function.prototype.bind ) {
        Function.prototype.bind = function ( oThis ) {
            if ( typeof this !== 'function' ) {
                // closest thing possible to the ECMAScript 5 internal IsCallable function
                throw new TypeError( 'Function.prototype.bind - what is trying to be bound is not callable' );
            }

            var aArgs = Array.prototype.slice.call( arguments, 1 ),
                fToBind = this,
                fNOP = function () {
                },
                fBound = function () {
                    return fToBind.apply( this instanceof fNOP && oThis
                        ? this
                        : oThis,
                        aArgs.concat( Array.prototype.slice.call( arguments ) ) );
                };

            fNOP.prototype = this.prototype;
            fBound.prototype = new fNOP();

            return fBound;
        };
    }
    /**
     * ES5 support for legacy browser: Covers most common use cases
     * Disregards support for second param, support for this is not available
     * for older EcamScript engines
     */
    if ( !Object.create ) {
        Object.create = function ( o ) {
            if ( arguments.length > 1 ) {
                throw new Error( 'Object.create implementation only accepts the first parameter.' );
            }

            function F () {
            }

            F.prototype = o;
            return new F();
        };
    }

    /**
     * Object.makeCopy:
     * Make deep copy of an object by manually copying properties
     */
    if ( !Object.makeCopy ) {
        Object.makeCopy = function ( p, c ) {
            var c = c || {};
            for ( var i in p ) {
                if ( typeof p[i] === 'object' ) {
                    c[i] = (p[i].constructor === Array) ? [] : {};
                    Object.makeCopy( p[i], c[i] );
                }
                else {
                    c[i] = p[i];
                }
            }
            return c;
        };
    }

    /**
     * Pure javascript Object.extend with deep copy
     */
    if ( !Object.extend ) {
        Object.extend = function ( orig ) {
            if ( orig == null ) {
                return orig;
            }
            for ( var i = 1; i < arguments.length; i++ ) {
                var obj = arguments[i];
                if ( obj != null ) {
                    for ( var prop in obj ) {
                        if ( obj.hasOwnProperty( prop ) ) {
                            var getter, setter;

                            if ( obj.__lookupGetter__ && obj.__lookupSetter__ ) {
                                getter = obj.__lookupGetter__( prop );
                                setter = obj.__lookupSetter__( prop );
                            }

                            if ( getter || setter ) {
                                if ( getter ) {
                                    orig.__defineGetter__( prop, getter );
                                }
                                if ( setter ) {
                                    orig.__defineSetter__( prop, setter );
                                }
                            }
                            else if ( $.isPlainObject( orig[prop] ) ) {
                                // deep copy for nested objects
                                Object.extend( orig[prop], obj[prop] );
                            }
                            else {
                                orig[prop] = obj[prop];
                            }
                        }
                    }
                }
            }
            return orig;
        };
    }

    return (function ( window, document, undefined ) {

        var Corpus = window.Corpus = {

            name: 'Corpus',

            version: '0.0.1',

            namespace: function ( ns_str, scope ) {
                var parts = ns_str.split( '.' ), parent = scope || upi, i = 0, l;

                // filter redundant ns global
                if ( parts[i] === 'Corpus' ) {
                    parts = parts.slice( 1 );
                }

                for ( i, l = parts.length; i < l; i++ ) {
                    // create ns if not exists
                    if ( typeof parent[parts[i]] === 'undefined' ) {
                        parent[parts[i]] = {};
                    }
                    parent = parent[parts[i]];
                }

                return parent;
            },

            modules: {},

            init: function ( scope, modules, options ) {
//                        console.log( 'init:', arguments );

                var module_arr,
                    module_paths,
                    initialized_modules = [],
                    args = [scope, options];

                this.scope = scope || this.scope;
                // cleanup optional params

                if ( modules && typeof modules === 'string' ) {

                    if ( /off/i.test( modules ) ) {
                        // dismantle all modules
                        return this.destroy();
                    }

                    module_arr = modules.split( ' ' );

                    // load AMD modules
                    if ( module_arr.length > 0 ) {
                        module_arr.forEach( function ( module ) {
                            initialized_modules.push( this.init_module( module, args ) );
                        }.bind( this ) );
                    }

                }

            },

            destroy: function () {
                // remove all attached events for this scope
                $( this.scope ).off( '.corpus' );
                $( window ).off( '.corpus' );
                return true;
            },

            create: function ( scope, module, options ) {
//                        console.log( 'initialized %s', this.name, arguments );
                var self = this;
                $( scope ).find( '[data-' + module + ']' ).each( function ( index, el ) {
                    // create a scoped instance for initialization
                    var scoped_module = Object.create( module );

                    // merge options with a copy of default settings so scoped modules can be configured independently
                    scoped_module.init( el, $.extend( true, Object.makeCopy( options ), options ) );

                }.bind( this ) );

                return this;
            },

            _settings: {},

            setup: function ( options ) {
                Object.extend( this._settings, options || {} );
            },

            getGlobalOptions: function ( moduleName ) {
                return Object.makeCopy( this._settings[moduleName] || {} );
            },

            // execute the modules initializer, passing this.scope and settings provided
            init_module: function ( module, args ) {
                return this.trap( function () {
                    if ( this.modules.hasOwnProperty( module ) ) {
                        var mod = this.modules[module];
                        return mod.init.apply( mod, args );
                    }
                }.bind( this ), module );
            },

            /**
             * parse serialized string to object notation in the form "key:value, key:value"
             * @param str
             * @returns {{}}
             */
            parse_options: function ( str ) {
                var options = {},
                    options_arr;

                if ( typeof str !== 'string' || !str ) {
                    return options;
                }
                options_arr = str.split( ',' );
                options_arr.forEach( function ( item ) {
                    var key = item.split( ':' );
                    options[key[0].replace( '-', '_' )] = key[1];
                } );

                return options;
            },

            trap: function ( fn, module ) {
                // try to initialize modules, handle exceptions that may occur
                try {
                    return fn();
                }
                catch ( e ) {
                    return this.error( {
                        name: module,
                        message: 'failed to initialize',
                        more: [e.name, e.message].join( ' ' )
                    } );
                }
            },

            // format
            error: function ( err ) {
                console.error( err.name, err.more );
                return err.name + ' ' + err.message + '; ' + err.more;
            }

        };

        // attach Corpus to the global library object
        $.fn.corpus = function () {
            var args = Array.prototype.slice.call( arguments );

            return this.each( function () {
                Corpus.init.apply( Corpus, [this].concat( args ) );
            } );
        };

        return Corpus;

    })( window, window.document );

})( libGlobal );
